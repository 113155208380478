<template>
    <div style="width:100%">
        <div style="display:inline-flex;width:11.111%;padding:0 5px" v-for="(tab,index) of tabs" :key="index">
            <b-button :class="tab.class" @click='goTo(tab.href)'>{{tab.name}} </b-button>
        </div>
    </div>
</template>
<script>

export default{
    props: ['tabs'],
    methods: {
        goTo(path){
            this.$router.push({path: path})
        }
    },
}
</script>

<style>
.mytab{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mytab2{
  width:100%;
  background:rgb(4, 112, 155) !important;
  font-size:.8rem;
  color:#FFF;
  border:0px solid #fff;
}
.mytab4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mytab3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mytab1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.mytab5{
  width:100%;
  font-size:.8rem;
  background:#339cfe !important;
  border:0px solid #fff;
}
.mytab6{
  width:100%;
  font-size:.8rem;
  background:#fe3344 !important;
  border:0px solid #fff;
}
</style>
