<template>
    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />
        <div style="margin:auto;width:75%">
        <v-row class="m-3">
            <v-col cols="12" md="6" sm="12">
                <v-text-field
                    :label="`الاسم المختصر ` + lang.shortcust_name + `*`"
                    v-model="settings.site_title"
                    >{{ settings.site_title }}</v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-text-field
                    :label="`اسم الشركة/المؤسسة ` +lang.company_name+ ` *`"
                    v-model="settings.company_name"
                    >{{ settings.company_name }}</v-text-field>
            </v-col>
        </v-row>
        <v-row class="m-3">
            <v-col cols="12" md="6" sm="12">
                <v-text-field
                    :label="`الرقم الضريبي ` +this.lang.vat_number+ ` *`"
                    v-model="settings.vat_number"
                    >{{ settings.vat_number }}</v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-text-field
                    label="العنوان *"
                    v-model="settings.address"
                    >{{ settings.address }}</v-text-field>
            </v-col>
        </v-row>
        <v-row class="m-3">
            <v-col cols="12" md="6" sm="12">
                <v-text-field
                    type="file"
                    ref="site_logo"
                    label="شعار الشركة لون ابيض *"
                    ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-text-field
                    type="file"
                    ref="site_logo"
                    label="شعار الشركة للفاتورة *"
                    ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="m-3">
            <v-col cols="12" md="6" sm="12">
                <v-textarea
                    label="شروط الاستخدام"
                    v-model="settings.terms"
                    >{{ settings.terms }}</v-textarea>
            </v-col>
            
            <v-col cols="12" md="6" sm="12">
                <v-textarea
                    label="التفويض"
                    v-model="settings.tafweed"
                    >{{ settings.tafweed }}</v-textarea>
            </v-col>
        </v-row>
        <div>
            <small style="color:red">* في حال تغيير الرقم الضريبي سيتوجب عليك إعادة تفعيل الترخيص</small>
        </div>
         <v-btn style="background:green;color:#FFF;margin-bottom:30px;" @click="updateSettings()">{{lang.save}}</v-btn>
        </div>
        
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
export default{
    components: {BreadCrumbs, TabsComp},
    data() {
        return {
            active_tab:0,
            settings:{
                site_title: '',
                company_name: '',
                vat_number: '',
                logo: '',
                address: '',
                tafweed: '',
                terms: '',
            },
            
        }
    },
    methods: {
        getSettings()
        {
            const post = new FormData();
            post.append("type",'getSettings');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.settings = res.results.data;
                }
            )
        },
        updateSettings(){
            const post = new FormData();
            post.append("type",'updateSettings');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[site_title]",this.settings.site_title);
            post.append("data[company_name]",this.settings.company_name);
            post.append("data[vat_number]",this.settings.vat_number);
            post.append("data[logo]",this.settings.logo);
            post.append("data[address]",this.settings.address);
            post.append("data[tafweed]",this.settings.tafweed);
            post.append("data[terms]",this.settings.terms);

            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    this.settings = res.results.data;
                    this.$store.state.settings = res.results.data;
                    this.$snotify.success('تم حفظ الاعدادات ..\nيرجى اخذ العلم انه اذا قمت بتغيير الرقم الضريبي سيتوجب عليك اعادة تفعيل النسخة', {
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            )
        }
    },
    created() {
        this.getSettings();
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function() {
            return {
                    text: this.lang.system_settings,
                    disabled: true,
                    to: '/settings/system-settings',
                }
        },
        tabs: function() {
            return [
                {
                    index:0,name:this.lang.system_settings,href:'/settings/system-settings', class: 'mytab'
                },
                {
                    index:1,name:this.lang.users,href:'/settings/users-settings', class: 'mytab2'
                },
                {
                    index:2,name:this.lang.permission,href:'/settings/permissions-settings', class: 'mytab3'
                }
            ]
        }
    },
}
</script>